import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { WideButton } from "../common/button"
import { getProductUrl } from "../../util/data"

const ProductCard = ({ product }) => {
  return (
    <div className="group flex flex-col gap-2 w-full border rounded-xl focus-within:bg-white hover:bg-white bg-gray-50 border-sky-100 p-4 focus-within:border-sky-500 hover:border-sky-500 transition-colors duration-300">
      <Link
        to={getProductUrl(product.handle)}
        className="relative w-full aspect-square rounded-xl overflow-hidden border border-sky-50 group-focus-within:border-sky-100 group-hover:border-sky-100 transition-colors duration-300"
      >
        <GatsbyImage
          alt={product.featuredImage.altText || ""}
          image={product.featuredImage.localFile.childImageSharp.gatsbyImageData}
          className="w-full aspect-square bg-white flex items-center justify-center overflow-hidden rounded-xl "
          imgClassName="w-full aspect-square object-contain rounded-xl overflow-hidden"
        />
        {product.images.length > 1 && (
          <GatsbyImage
            alt={product.title}
            className="absolute rounded-xl inset-0 bg-white scale-105 group-hover:scale-100 group-focus-within:scale-100 opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 duration-300 overflow-hidden"
            imgClassName="w-full object-contain rounded-xl overflow-hidden"
            image={
              product.images.find(image => image.shopifyId !== product.featuredImage.id).localFile
                .childImageSharp.gatsbyImageData
            }
          />
        )}
        <div className="flex opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity duration-300 justify-center items-end absolute inset-0 p-4">
          <WideButton>Ver detalle</WideButton>
        </div>
      </Link>
      <div className="flex flex-col justify-center text-center">
        <Link
          to={getProductUrl(product.handle)}
          className="hover:text-sky-500 focus-visible:text-sky-500 transition-colors duration-300"
        >
          {product.title}
        </Link>
        <div className="text-sm">{product.vendor}</div>

        <div className="font-bold">
          {product.priceRangeV2.maxVariantPrice.amount !==
            product.priceRangeV2.minVariantPrice.amount && "desde "}
          S/{product.priceRangeV2.minVariantPrice.amount}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
