import { graphql } from "gatsby"
import React from "react"
import List from "../../components/product/list"
import Layout from "../../components/common/layout"
import HeadGenerator from "../../components/common/seo"
import data from "../../data/fields"
import summaryImage from "../../images/summary_image.webp"

const ProductsPage = ({ data: { products } }) => {
  return (
    <Layout>
      <List products={products.nodes} />
    </Layout>
  )
}

export default ProductsPage

export const Head = () => (
  <HeadGenerator
    data={{
      title: "Tienda Jockey On Ice",
      description: "¡Encuentra los acompañantes perfectos para tu aventura en el hielo!",
      url: data.prefix.productDetail,
      image: {
        srcWebp: summaryImage,
        widh: 1200,
        height: 630,
        altText: data.strings.seo.default.title,
      },
    }}
    largeSummaryImage
  />
)

export const query = graphql`
  query {
    products: allShopifyProduct(
      filter: { shopifyId: { ne: "gid://shopify/Product/8018929877283" } }
    ) {
      nodes {
        id
        handle
        title
        featuredImage {
          id
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: [WEBP], sizes: "600w 400w 200w")
            }
          }
        }
        images {
          shopifyId
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: [WEBP], sizes: "600w 400w 200w")
            }
          }
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        vendor
      }
    }
  }
`
