import React from "react"
import { LayoutSection } from "../common/layout"
import ProductCard from "./card"
import { SimplePaddedCard } from "../common/cards"

const List = ({ products }) => (
  <LayoutSection>
    <div className="w-full max-w-5xl mx-auto p-8 flex flex-col gap-8">
      <SimplePaddedCard>
        <h2 className="text-4xl font-bold">Productos</h2>
      </SimplePaddedCard>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-center sm:justify-between flex-wrap">
        {products.map((product, i) => (
          <ProductCard product={product} key={i} />
        ))}
      </div>
    </div>
  </LayoutSection>
)

export default List
